import ColumnView from '../ColumnView';

const AboutSession = () => {
  return (
    <div id="about">
      <ColumnView title="About App" subTitle="Welcome to PRM Online">
        <p>
          PRMOnline.ca is a state-of-the-art SaaS platform designed specifically for businesses looking to streamline their media data management. The
          solution was created to enable seamless data transformation from multiple advertising platforms into a single, centralized location. This
          approach allows businesses to aggregate data, measure campaign effectiveness, and make data-driven decisions to optimize their media
          investments.
        </p>
        <p>
          Developed with cutting-edge technology, PRMOnline offers robust features to monitor and measure the effectiveness of third-party digital
          media and media budgets in a centralized location. Whether you're managing one media/campaign or a hundred, the app keeps your business
          organized and on track.
        </p>
      </ColumnView>
      <ColumnView
        title="What App Provides"
        theme="light"
        header="Easy to manage, monitor, and measure your digital media investments, all while ensuring security and compliance with industry standards."
        flexDirection="row-reverse">
        <p>
          <ul>
            <li>
              <span className="bold">Efficient Data Aggregation:</span> Collect and consolidate media data from multiple platforms into one easily
              manageable system.
            </li>
            <li>
              <span className="bold">Customizable Reporting Dashboards:</span> Visualize your media performance in real-time and generate detailed
              reports for stakeholders.
            </li>
            <li>
              <span className="bold">Campaign Optimization:</span> Track the efficiency of your media budgets and refine your approach based on data
              insights.
            </li>
            <li>
              <span className="bold">Scalable Solutions: </span> Built with flexibility and scalability to meet the growing demands of your business.
            </li>
          </ul>
        </p>
      </ColumnView>
    </div>
  );
};

export default AboutSession;
