import { RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { router } from './router/Router';

function App() {
  return <RouterProvider router={router} />;
}

export default App;
