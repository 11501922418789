import { Container } from 'reactstrap';

const ContactSession = () => {
  return (
    <Container fluid id="contact" className="bg-primary" style={{ padding: 10 }}>
      <div style={{ textAlign: 'center' }}>
        If you have questions about our services, need technical support, or want to learn more about how PRMOnline.ca can benefit your business,
        please contact:{' '}
        <a style={{ color: '#ffffff', fontWeight: 700 }} href="mailto: contact@stacktics.com">
          contact@stacktics.com
        </a>
      </div>
    </Container>
  );
};

export default ContactSession;
