import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import HomePage from '../pages/homePage';
import PrivacyPage from '../pages/privcay';
import { useEffect } from 'react';

const Root = (): JSX.Element => {
  const location = useLocation();
  useEffect(() => {
    if (!!location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));
      element?.scrollIntoView({ block: 'start' });
    }
  }, [location]);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
      </Routes>
    </>
  );
};

export default Root;
