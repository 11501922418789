import CardView from '../CardView';
import ColumnView from '../ColumnView';

const contents = [
  {
    title: 'Multi-Platform Data Transformation',
    body: 'Integrate data from top advertising platforms, transforming fragmented campaign data into a single, comprehensive view. This streamlined approach allows you to aggregate data at an aggregate level and track campaign performance across all platforms.',
  },
  {
    title: 'Centralized Reporting & Analytics',
    body: 'The powerful reporting tools enable you to monitor media effectiveness and budget performance from one place. With real-time analytics and custom reports, gain complete visibility into your media investments and make informed decisions that drive results.',
  },
  {
    title: 'Secure Cloud-Based Storage',
    body: 'PRMOnline.ca is built on a secure, cloud-based infrastructure that ensures the integrity and security of media data. The platform is designed to comply with industry-leading security standards while remaining flexible to scale.',
  },
  {
    title: 'Campaign Optimization & Budget Monitoring',
    body: 'Measure the effectiveness of digital media spending, track media budgets, and adjust to maximize return on investment. With insights, optimize campaigns to ensure media dollars are being spent efficiently.',
  },
];

const ServicesSession = () => {
  return (
    <div id="services">
      <ColumnView title="Our Services" theme="primary">
        <p>
          At PRMOnline.ca, the focus is on transforming how businesses handle their media and campaign data. The platform provides end-to-end services
          that make media management efficient and transparent, from data transformation to insightful reporting.
        </p>
      </ColumnView>
      <CardView
        title="Services"
        subTitle="Transform your media management and maximize the impact of your media investments today."
        cardContents={contents}
        theme="light"
      />
    </div>
  );
};

export default ServicesSession;
