import { AboutSession, ContactSession, MainSession, ServicesSession } from '../components/HomePage';

const HomePage = (): JSX.Element => {
  return (
    <div>
      <MainSession />
      <AboutSession />
      <ServicesSession />
      <ContactSession />
    </div>
  );
};

export default HomePage;
