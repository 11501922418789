import { Button, Container } from 'reactstrap';

const MainSession = () => {
  return (
    <Container fluid id="home" className="bg-image">
      <div className="main-session">
        <h2 style={{ marginBottom: '20px' }}>Empower your business with data-driven media management.</h2>
        <p>PRMOnline.ca is a powerful SaaS solution designed to streamline data transformation and management for centralized media reporting.</p>
        <p>
          It has easy-to-use features, such as managing media data and gaining insights into campaign performance. PRMOnline.ca allows you to track,
          analyze, and optimize media and marketing spend to make more informed decisions.
        </p>
        <p style={{ marginBottom: '50px' }}></p>
        <Button color="primary" href="/prm/login">
          Login to PRM Online
        </Button>
      </div>
    </Container>
  );
};

export default MainSession;
