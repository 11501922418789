import { Link } from 'react-router-dom';
import { Button, Nav, Navbar, NavbarBrand, NavItem } from 'reactstrap';

const Header = (): JSX.Element => {
  return (
    <Navbar color="primary" dark expand container="fluid">
      <NavbarBrand>PRM Online</NavbarBrand>
      <Nav className="me-auto">
        <NavItem>
          <Link className="header-link" to="/#home">
            Home
          </Link>
        </NavItem>
        <NavItem>
          <Link className="header-link" to="/#about" preventScrollReset={false}>
            About
          </Link>
        </NavItem>
        <NavItem>
          <Link className="header-link" to="/#services">
            Services
          </Link>
        </NavItem>
        <NavItem>
          <Link className="header-link" to="/#contact">
            Contact
          </Link>
        </NavItem>
        <NavItem>
          <Link className="header-link" to="/privacy">
            Privacy
          </Link>
        </NavItem>
      </Nav>
      <Button color="primary" href="/prm/login">
        Login to PRM Online
      </Button>
    </Navbar>
  );
};

export default Header;
