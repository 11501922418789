import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';

interface ICardView {
  title: string;
  subTitle?: string;
  cardContents: { title: string; body: string }[];
  theme?: 'primary' | 'light' | 'default';
}

const containerClass = {
  primary: 'bg-primary',
  light: 'light-background',
  default: '',
};

const CardView = ({ title, subTitle, cardContents, theme = 'default' }: ICardView): JSX.Element => {
  return (
    <Container fluid className={containerClass[theme]} style={{ padding: '0 10%' }}>
      <Row style={{ height: '500px', alignItems: 'center' }}>
        <Col xs={12}>
          <div className="heading-title text-primary" style={{ margin: 'auto', width: 'fit-content' }}>
            {title}
          </div>
          {subTitle && (
            <div className="subTitle" style={{ margin: 'auto', width: 'fit-content' }}>
              {subTitle}
            </div>
          )}
        </Col>

        <Col xs={12}>
          <Row style={{ justifyContent: 'center' }}>
            {cardContents.map((content) => (
              <Col xs={3} key={content.title}>
                <Card style={{ width: '20rem', height: '21rem' }}>
                  <CardBody>
                    <CardTitle tag="h4" className="text-primary">
                      {content.title}
                    </CardTitle>
                    <CardText>{content.body}</CardText>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CardView;
