import { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';

interface IColumnView {
  theme?: 'primary' | 'light' | 'default';
  header?: string;
  title: string;
  children: ReactNode;
  subTitle?: string;
  flexDirection?: 'row' | 'row-reverse';
}

const containerClass = {
  primary: 'bg-primary',
  light: 'light-background',
  default: '',
};

const ColumnView = ({ title, header, subTitle, children, flexDirection = 'row', theme = 'default' }: IColumnView) => {
  return (
    <Container fluid style={{ padding: '0 10%' }} className={containerClass[theme]}>
      <Row style={{ height: '500px', alignItems: 'center', flexDirection }}>
        <Col xs={6}>
          <div className="heading-title text-primary" style={{ ...(flexDirection === 'row-reverse' && { textAlign: 'right' }) }}>
            {title}
          </div>
          {subTitle && <div className="heading-title ">{subTitle}</div>}
        </Col>
        <Col xs={6}>{children}</Col>
        {header && (
          <Col xs={12}>
            <div className="subTitle">{header}</div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ColumnView;
